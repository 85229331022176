
.upload-box{
  font-size: 16px;
  background: white;
  border-radius: 50px;
  box-shadow: 2px 2px 4px black;
  width: 390px;
  outline: none;
}

::-webkit-file-upload-button{
  color: white;
  background: #1D1D1D;
  padding: 12px;
  border: none;
  border-radius: 50px;
  box-shadow: 1px 0 1px 1px #6b4559;
  outline: none;
}

::-webkit-file-upload-button:hover{
  background: #303030;
 
}

@media (max-width: 768px) {
  .upload-box {
    font-size: 13px;
    width: 240px;
  }
}