.custom-event {
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.event-reservation {
    background-color: #af3a3a; /* Rojo para eventos de reserva */
    border: #9e9e9e;
}

.event-available {
    background-color: #39b139; /* Verde para eventos de horarios disponibles */
    border: #9e9e9e;
}

