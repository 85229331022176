.cardF-state{
    display: block !important;
    animation: bounce; /* referring directly to the animation's @keyframe declaration */
}
.card-state-enableF{
    display: none !important;
}
.cardB-state{
    display: none !important;
}
.card-state-enableB{
    display: block !important;
    animation: flipInX; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 0.5s; /* don't forget to set a duration! */
}