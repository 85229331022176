.btn-asignar-horario{
    color:#fff !important;
    background-color: #fff !important;
}
.ico-select{
    font-size: small !important;
    margin-top: 4px !important;
    margin-right: 10px !important;
}
.ico--socio-invitado{
    color:#EBB433 !important
}
.ico--socio{
    color:rgb(232, 89, 0) !important

}
.ico--profesores{
    color:#418453 !important

}
.ico--externos{
    color:#2F6B88 !important

}
.btn-socio-invitado{
    background-color: #EBB433 !important;
    color:#EBB433 !important;
    border: none!important;
}
.btn-socio{
    background-color: rgb(232, 89, 0) !important;
    color:rgb(232, 89, 0) !important;
    border: none!important;
}
.btn-profesores{
    background-color: #418453 !important;
    color:#418453 !important;
    border: none!important;
}
.btn-externos{
    background-color: #2F6B88 !important;
    color:#2F6B88 !important;
    border: none!important;
}