@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap');

body{
    background-color: white!important;
}
.linkcss-back{
    color: rgb(0, 37, 90);
    /* font-family: 'Tourney', cursive !important; */
    /* font-family: 'Basic Sans' !important;  */
}
.TitleInvite{
    color: rgb(0, 37, 90);
    /* font-family: 'Tourney', cursive !important; */
    /* font-family: 'Basic Sans' !important;  */
    font-weight: bolder !important;   
}
.btn-agregar{
    color: white !important;
    background-color: #469489 !important;
    font-size: smaller !important;
}
.containerInvite{
    padding: 7px;
}
.btn-editar{
    color: white !important;
    background-color: #469489 !important;
    font-size: smaller !important;}
.btn-eliminar{
    color: white !important;
    background-color: #b10202 !important;
    font-size: smaller !important;}
.btn-cancelar{
    color: white !important;
    background-color: #b10202 !important;
    font-size: smaller !important;}
.btn-aceptar{
    color: white !important;
    background-color: #e4980d!important;
    font-size: smaller !important;}
.movil{
    display: none !important;
}
@media (max-width: 672px ) {
.web{
    display: none !important;
}
.movil{
    display: block !important;
}
}
.contenedor_file--input{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor:pointer
}
.btn-Hover:hover{
background: #469489 !important;
}





.file-select {
    position: relative;
    display: inline-block;
  }
 
  .file-select::before {
    background-color: #155172;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    content: 'Seleccionar'; /* testo por defecto */
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 135%;
  }
 
  .file-select input[type="file"] {
    opacity: 0;
    width: 200px;
    height: 32px;
    display: inline-block;
  }
 
  #src-file1::before {
 
    content: 'Agregar imagen';
  }
 
 
  .wrapper{
 
    width:100%;
    height:100%;
    display:flex;
  }
 
    .file-upload{
 
      height:139px;
      width:149px;
      border-radius: 20px;
      position:relative;
      display:flex;
      justify-content:center;
      align-items: center;
      border:4px solid #F3F3F3;
      overflow:hidden;
      background-size: 100% 200%;
      transition: all 1s;
      color: #F3F3F3;
      font-size:100px;
    }
    .file-upload input[type='file']{
        height:300px;
        width:300px;
        position:absolute;
        top:0;
        left:0;
        opacity:0;
        cursor:pointer;
      }
 
      .file-upload:hover{
        background-position: 0 -100%;
        color:#F3F3F3;
        background-image: linear-gradient(to bottom, #469489 50%, #469489 50%);
      }
 
 
 