.reserca-exito {
   border: 2px solid #82b3ac;
   padding: 15px 30px !important;
   border-radius: 15px;
}

.card-btn-ver {
   color: #fff !important;
   border: 1px solid #82b3ac !important;
   background-color: #82b3ac !important;
   font-size: smaller !important;
   border-radius: 10px !important;
}

.card-btn-ver:hover {
   background-color: #42867c !important;

   ;
}

.card-tipe__checked {
   background-color: #E4980D !important;
   padding: 30px 10px !important;
   border-radius: 15px !important;
   cursor: pointer !important;
}

.card-tipe {
   background-color: #191D3D !important;
   padding: 30px 10px !important;
   border-radius: 15px !important;
   cursor: pointer !important;
}

.card-tipe:hover {
   background-color: rgb(59, 59, 59, 0.9) !important;
}

.card_reservation--action {
   color: #fff !important;
   border: 1px solid #BB1F1F !important;
   background-color: #BB1F1F !important;
   font-size: smaller !important;
   border-radius: 10px !important;
   padding: 10px !important;
}
.card_reservation--submit {
   color: #fff !important;
   border: 1px solid #191D3D !important;
   background-color: #191D3D !important;
   font-size: smaller !important;
   border-radius: 10px !important;
   padding: 10px !important;
}