body{
    background-color:rgb(247, 247, 247)!important;
}
.textMain{
  font-weight: bolder ;
  font-size: xx-large !important;
  text-align: center !important;
}
.text-card{
color: #000000;
text-align: center;
font-size: medium;
}
.contenedor-cards{
    position: absolute;
    top: 30%;
    height: 30%;
    width: 50%;
    /* margin: -15% 0 0 -0%; */
}
.contenedor{
    position: relative;
    display: inline-block;
    /* text-align: center; */
}
.texto-encima{
    position: absolute;
    bottom: 1px;
    background-color: rgba(0, 0, 0, 0.595) !important;

}
.button_back{
  cursor: pointer;
  font-weight: 500 !important;
}
.texto-card{

/* -webkit-text-stroke: 0.7px rgb(8, 8, 8); */
color: rgb(255, 255, 255);
font-weight: 500 !important;
}
.texto-card2{
    color: rgb(255, 255, 255);
}

.MuiTabs-flexContainer{
justify-content: center !important;
}
.title1{
    color:#1c4e81;
    font-weight: 800;
    border: 1px solid #efefef;
    background-color: #ffffff;
    padding: 0px 5px;
    border-radius: 10px;
}
.sdp {
    --theme-color: #1c4e81 !important; /* violet color */
  }
  .sdp {
  --selected-date-color: #eeeeee !important; /* light-gray color */
}
.sdp {
    --font: 'Times New Roman', sans-serif !important;
  }
  .title2{
    color:#00264f;
    font-weight: bolder !important;

  }
  .backMain{
    min-height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; 
  }