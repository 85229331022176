body{
    background-color: #fff;
}
.text-card{
color: #000000;
font-family: 'Times New Roman', Times, serif;
text-align: center;
font-size: medium;
}
.contenedor-cards{
    position: absolute;
    top: 30%;
    height: 30%;
    width: 50%;
    /* margin: -15% 0 0 -0%; */
}
.contenedor{
    position: relative;
    display: inline-block;
    /* text-align: center; */
}
.texto-encima{
    position: absolute;
    bottom: 1px;
    background-color: rgba(0, 81, 38, 0.5);
}
.texto-card{
color: rgb(255, 255, 255);
font-weight: bolder !important;
/* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
}
.texto-card2{
    color: rgb(255, 255, 255);
    /* font-weight: bolder !important; */    
}

    