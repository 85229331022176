.reserca-exito{
    border: 2px solid #82b3ac;
    padding: 15px 30px !important;
    border-radius: 15px;
}
.card-btn-ver-success{
color: #fff!important;
    border: 1px solid #82b3ac !important;
    background-color: #82b3ac!important;
    font-size: 16px !important;
    border-radius: 10px!important;}
    .card-btn-ver:hover{
            background-color: #42867c!important;

 ;}
