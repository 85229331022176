.bodyga{
    /* background: rgb(230, 230, 230)!important; */
}
.cardGrid{
    /* height: 100vh; */
}
.cardGrid__single{
    /* height: 100vh; */
}
.cardGrid__single--link{
    text-decoration: none;
}
.cardGrid__single--card1{
    /* height: 100vh; */
    display: flex;
    align-content: center;
    background: #e1400a !important;
    /* border: 3px solid rgb(0, 224, 157); */
    /* margin: 5px 0px 5px 5px; */
    border-radius: 15px!important;
}

.cardGrid__single--card2{
    /* height: 100vh; */
    display: flex;
    align-content: center;
    background: #ffa200db!important;
    /* border: 3px solid rgb(255, 208, 0); */
    /* margin: 5px 5px 5px 0px; */
    border-radius: 15px!important;
}

.cardGrid__single--title{
    font-weight: bold!important;
    /* background: rgb(255, 187, 0); */
    font-style: italic;
    text-shadow: 4px 0px 0px #000000;
    /* font-family: 'Basic Sans' !important;  */

}
.cardGrid__single--subtitle1{
    font-weight: bold!important;
    /* background: rgb(255, 187, 0); */
    font-style: italic;
    /* text-shadow: 5px 5px 0px #000000; */
    /* font-family: 'Basic Sans' !important;  */

}
.cardGrid__single--subtitle2{
    font-weight: bold!important;
    /* background: rgb(255, 187, 0); */
    font-style: italic;
    /* text-shadow: 5px 5px 0px #000000; */
    /* font-family: 'Basic Sans' !important;  */
}