
@import url('https://fonts.googleapis.com/css2?family=Tourney:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Palanquin+Dark&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gemunu+Libre:wght@300&display=swap'); 

.contenedor3{
    min-height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.text-principal{
    font-family: 'Tourney', cursive !important;
    color: rgb(255, 187, 0) !important;
    font-size: 80px !important;
    text-shadow: 0.05em 0.08em 0.08em rgb(3, 3, 3) !important;

}
.text-secundario{
    font-family: 'Dancing Script', cursive !important;
    color: rgb(255, 255, 255) !important;
    font-size: 48px !important;
    font-weight: bold;
    text-shadow: 0.05em 0.05em 0.1em rgb(3, 3, 3) !important;
}
.text-white{
    color: #fff !important;
}
.text-parrafo{
    font-family: 'Gemunu Libre', sans-serif !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: bold;
    text-shadow: 0.05em 0.05em 0.1em rgb(3, 3, 3) !important;
}
.book-here{
    background-color: #00c3ff !important;
    color: rgb(255, 255, 255) !important;
    font-size: medium !important;
    font-family: 'Palanquin Dark', sans-serif !important;
    padding: 9px 25px!important;
    cursor: pointer;
    width: 100%;
}
.gif-config{
    max-width: 100% !important;
    margin-left: 10% !important;
   
}



