/*============ Google fonts ============*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200&family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Condiment&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans+Pinstripe&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tourney:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');
body{
  background-color: #F6F5FA !important;
}
.titulo-principal{
  font-family: 'Righteous', cursive !important;
  color: #000000 !important;
}
.titulo-secundario{
  font-family: 'Lato', sans-serif;
  letter-spacing: 2px !important;
  color: #727272 !important;
}
.title-principal{
  font-family: 'Dosis', sans-serif;
  letter-spacing: 3px!important;
  color: #b10202;
  text-shadow: rgb(160, 160, 160) 0.05em 0.05em 0.1em;
  font-weight: 600!important;
}
.title-principal2{
  color: rgb(255, 191, 0);
  text-shadow: rgb(160, 160, 160) 0.05em 0.05em 0.1em;
}

:root{
  /*===== Colors =====*/
  --card-01-color-01: rgb(255, 255, 255);
  --card-01-color-02: rgb(255, 255, 255);
  --card-02-color-01: #ffffff;
  --card-02-color-02: #ffffff;
  --card-03-color-01: #ffffff;
  --card-03-color-02: #ffffff;
  --card-body-bg-color: #fff;
  --text-disabled-color: #b2b2b2;
  --icon-disabled-color: #ff0404;
  --body-bg-color: #e5f6ff;

  /*===== Fonts =====*/
  --bebas-font: 'Roboto', sans-serif;
}
.card-container{
  box-shadow: none!important;
  padding: 5px!important;
  transition: transform .2s;

}
.card-container:hover{
  transform: scale(1.04);
}

.pricing-card{
  position: relative;
}

.card-body{
  width: 100%;
  background: var(--card-body-bg-color);
  border-radius: 20px 20px 20px 20px;
  /* box-shadow: 0 5px 25px rgba(86, 86, 86, 0.2); */
  margin-top: 10px;
  background-color: #ffffff;
}

.card-body .top-shape{
  height: 200px;
  border-radius: 20px 20px 20px 20px!important;
}

.card-01 .card-body .top-shape{
  background: var(--card-01-color-01);
}

.card-02 .card-body .top-shape{
  background: var(--card-02-color-01);
}

.card-03 .card-body .top-shape{
  background: var(--card-03-color-01);
}

.card-body .top-shape:before{
  content: '';
  width: 100%;
  height: 190px;
}
.card-content{
  margin-top: 10px !important;
}

.pricing{
  z-index: 999;
  font-family: var(--bebas-font);
  text-align: center;
  position: absolute;
  width: 100%;
}

.price{
  display: flex;
  justify-content: center;
  margin-bottom: 3px;

}

.price span{
  font-size: 9em;
  line-height: 100px;
}

.price sup{
  font-size: 2em;
  transform: translateY(-15px);
}

.pricing p{
  font-family: var(--poppins-font);
  font-weight: 500;
  margin-bottom: 10px;
}

.pricing .type{
  text-transform: uppercase;
  font-size: 1.8em;
  font-weight: 600;
}

.card-body .card-content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translateY(-30px);
}

.card-body .card-content ul{
  width: 100%;
  font-family: var(--poppins-font);
  text-align: center;
  margin-bottom: 20px;
  padding: 0 20px;
}

.card-body .card-content ul li{
  list-style: none;
  font-size: 0.95em;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 35px;
  white-space: nowrap;
}

.card-body .card-content ul li i{
  font-size: 1.9em;
  margin-left: 15px;
}

.card-content .buy-btn{
  font-family: var(--bebas-font);
  outline: none;
  padding: 7px 28px;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 15px;
  color:#b10202
}

 .buy-btn{
  border: 1px solid #FF7C3C;
  color: #fff !important;
  background-color: #FF7C3C;
}

/* border: 1px solid #b10202;
  color: #fff !important;
  background-color: #b10202; */
  .backModules{
    /* background-image: url("../../assets/background/imgcanchatree.jpg"); */
    min-height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (max-width: 537px ) {
    .title-principal{
      color:#FF2500;
      font-weight: 900!important;
      /* font-style: italic; */
      /* text-shadow: 3px 0px 0px #CC4C3B; */
      text-shadow: 1px 0px 0px #ffffff;
      letter-spacing: 3px!important;
      font-size: 50px !important;
  }
  .title-principal2{
      color:#ffdd00;
      font-weight: 600!important;
      /* font-style: italic; */
      /* text-shadow: 3px 0px 0px #CC4C3B; */
      text-shadow: 1px 0px 0px #ffffff;
      letter-spacing: 3px!important;
      font-size: 50px !important;
  }
  .containerModulos{
    margin-top: 0 !important;
  }
  }