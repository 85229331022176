
*{
margin: 0;
padding: 0;
}

.book-here{
    background-color: #31B177 !important;
    color: black;
    padding: 9px 25px!important;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
}
.match-here{
    background-color: #1b929f !important;
    color: white;
    padding: 9px 25px !important;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
}
.text-white{
    color: #fff !important;
}

.text-pink{
    color: #B22148 !important;
}
.bkRegister{
    background-image: url('https://img.freepik.com/foto-gratis/pelota-tenis-linea-esquina-corte-dura_1150-6396.jpg?w=1380&t=st=1661273432~exp=1661274032~hmac=83ecb35c9590f6c6363ce839cb39e483468c8354f6c29e90f5052d4b553646f7');
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
}