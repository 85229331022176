
.cardDeporte{
background-color: #ffffff !important;
height: 200px !important;
padding: 10px !important;
margin: 10px !important;
border-radius: 20px 20px 20px 20px !important;
border: none !important;
cursor: pointer;
}
