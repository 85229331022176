*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font: 87.5%/1.5em 'Lato', sans-serif;
}

.app {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 90px;
  padding: 0 20px;

  /* .input {
      
    } */

  /* input {
      padding: 7px 8px;
      font-size: 14px;
      width: 100%;
    } */
}

.app .input {
  margin-bottom: 20px;
  padding: 7px 8px;
  font-size: 14px;
  width: 100%;
}
.day{
  color:#b10202;
  background-color: rgb(255, 255, 255);
  padding: 12px 20px;
  border-radius: 15px;
  cursor: pointer;
}
.day-active{
  color:rgb(255, 255, 255);
  background-color: #b10202;
  padding: 12px 20px;
  border-radius: 15px;
  cursor: pointer;
}
.ico-day{
  color: #b10202 !important;
}
.ico--fondo{
  background-color: rgb(255, 255, 255) !important;

}
.fondo-hours{
  background-color: #191D3D;
  /* border-radius: 20px 20px 0 0; */
  padding: 1px 20px;
}
.fondo-hours-button{
  background-color: #b10202;
  border: #b10202;
  cursor: pointer;
  color: #fff;
  padding: 1px 10px;
}

.fondo-hours-button:hover{
  /* border: 2px solid #ffdfdf;
  padding: 5px 10px;
  cursor: pointer !important; */
  background-color: rgb(219, 9, 9)  !important;
  /* color:#fff */
}

.border-container{
  /* border: 1px solid #191D3D; */
  border-radius: 20px ;

}
.iconSeparater{
  /* margin-top: 20px !important; */
  color: #E4980D
}

.horaiosPair{
  border: 2px solid #ffdfdf;
 padding: 5px 10px;
 cursor: pointer !important;
 transition: 0.6s all;
}
.horarioOcupado{
  border: 2px solid #ffdfdf;
  padding: 5px 10px;
  cursor: pointer !important;
  background-color: #f0d774 !important;
  /* color:#fff */
}
.horarioOcupado:hover{
  /* border: 2px solid #ffdfdf;
  padding: 5px 10px;
  cursor: pointer !important; */
  background-color: rgb(0, 0, 0, 0.4)  !important;
  /* color:#fff */
}
.horaiosPair:hover{
  border: 2px solid #ffdfdf;
 padding: 5px 10px;
 cursor: pointer !important;
 /* background-color: #f0d774 !important; */
 color:#fff
}

.bg-salmon{
  background-color: #191D3D!important;
}