.contenedor__main{
    background-color: #F6F5FA!important;
}
.title__main{
    color: #202020;
    /* font-family: 'Tourney', cursive !important; */
    /* font-family: 'Basic Sans' !important;  */
    font-weight: bolder !important; 
    /* font-style: italic; */
}
.link__css{
    text-decoration: none!important;
    /* font-style: italic; */
    
    /* font-size: 22px; */    
    /* font-family:  Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}
.link__css-back{
    /* text-decoration: none; */
    font-weight: bold !important;
    color: #202020;
    /* color: #1c4e81!important; */
/* font-weight: bold; */
    /* font-size: 22px; */    
    /* font-family:  Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}
.icon_back{
    margin-bottom: -4px;
}

.card_reservation {
    border: 1.5px solid #E6E6E8;
    border-radius: 15px !important;
    background-color: #fff!important;
}

.card_reservation--club {
    color: #469489;
    /* font-style: italic; */
    font-weight: bold !important;
}
.card_reservation--club--icon{
    margin-bottom: -5px!important;
    margin-right: 5px;
}
.card_reservation--info {
    color: rgb(0, 37, 90)!important;
}

.card_reservation--button {
    color: #fff !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    background-color: #1da54c !important;
    text-transform: none!important;
}

.card_reservation--precio {
    color: rgb(0, 37, 90) !important;
    font-weight: 500 !important;
    /* margin-top: 10px !important; */
    margin-bottom: 10px !important;
}
.card_invite--list {
    color: "#191D3D" !important;
    font-weight: bold !important;
    /* margin-top: 10px !important; */
    margin-bottom: 0px !important;
}


/* D48387 */
.card_reservation--pago {
    color: rgb(0, 37, 90) !important;
    font-weight: bold !important;
}
.card_reservation--info {
    color: rgb(0, 37, 90);
}
.card-no-seleccionado{
    background-color: #191D3D !important;
    padding: 30px 10px !important;
    border-radius: 15px !important;
    cursor: pointer !important;
}
.card-no-seleccionado:hover{
    background-color: #E4980D !important;
   }
.card-seleccionado{
    background-color: #E4980D !important;
    padding: 30px 10px !important;
    border-radius: 15px !important;
    cursor: pointer !important;
}
.seleccionado:hover{
    background-color: #191D3D !important;
   }
.card_reservation--icon_person{
    margin-right: 7px!important;
    margin-top: -2px!important;
}
.card_reservation--icon{
    margin-bottom: -5px!important;
    margin-right: 5px!important;
}
.card_reservation--reservador{
    color: rgb(0, 37, 90) !important;
    font-weight: bold !important;
}
.card_reservation--here{
    text-decoration: none;
    color: #666666;
    /* font-weight: bold; */
}
.card_reservation--change{
    text-transform: none!important;
    color: #fff!important;
    border: 1px solid #E4980D !important;
    background-color: #E4980D !important;
    border-radius: 10px!important;
    font-size: 16px!important;
}
.card_reservation--change-add{
    text-transform: none!important;
    color: #fff!important;
    border: 1px solid #0de43c !important;
    background-color: #0de43c !important;
    border-radius: 10px!important;
    font-size: 16px!important;
}
.card_reservation--add{
    text-transform: none!important;
    color: #fff!important;
    border: 1px solid #191D3D !important;
    background-color: #191D3D!important;
    border-radius: 10px!important;
    font-size: 16px!important;
}
.card_reservation--cancel{
    color: #fff!important;
    border: 1px solid #b10202 !important;
    background-color: #b10202!important;
    border-radius: 10px!important;
    text-transform: none!important;
    font-size: 16px!important;
}
.card-invite1,.card-invite2,.card-invite3{
    background-color: #191D3D !important;
    padding: 30px 10px !important;
    border-radius: 15px !important;
    cursor: pointer !important;
}
.card-invite1:hover{
 background-color: #E4980D !important;
}
.card-invite2:hover{
    background-color: #b10202!important;
   }
.card-invite3:hover{
    background-color: #469489 !important;
   }
.text-modal-title{
   color: #191D3D!important;
   font-weight: 500 !important;
}
.txt-card-opcion{
    color: #ffffff;
    /* font-style: italic; */
    font-weight: bold !important;}

.btn-invitar-now{
    color: #fff!important;
    background-color: #E4980D !important;
    border-radius: 8px!important;
    font-size: smaller !important;
    cursor: pointer !important;
}
.btn-invited{
    color: #fff!important;
    background-color: #469489  !important;
    border-radius: 8px!important;
    font-size: smaller !important;
    cursor: pointer !important;
}
.text-item{
    color: #191D3D!important;
    font-size: 15px !important;
}

.btn-acepted-invitate{
    color: #fff!important;
    border: 1px solid #469489 !important;
    background-color: #469489!important;
    border-radius: 10px!important;
    text-transform: none!important;
    font-size: 16px!important;
}

/* Estilo para la card principal */
.hora-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  /* Estilo para las sub-cards de media hora */
  .media-hora-card {
    border: 1px dashed #ccc;
    border-radius: 8px;
    padding: 5px;
    margin-top: 5px;
  }
  
  /* Colores de fondo para las sub-cards */
  .sub-card1 {
    background-color: #f1c40f; /* Amarillo */
  }
  
  .sub-card2 {
    background-color: #3498db; /* Azul */
  }
  
  /* Color de fondo para la card individual de 30 minutos */
  .sub-card-individual {
    background-color: #e74c3c; /* Rojo */
  }