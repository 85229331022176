@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200&family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Condiment&display=swap');

.headerstyle{
    background-color: rgb(255, 255, 255) !important;
}

.title-uno{
    font-family: 'Dosis', sans-serif; 
    letter-spacing: 2px!important;
    color: #b10202;
    font-weight: 500!important;
  }
  .title-dos{
    color: rgb(255, 191, 0);
    font-weight: 500 !important;
  }
  .icon-perfil{
    size: 45px !important;
  }
  .btn-aceptar{
    background-color: #191D3D !important;
  }
  .btn-cancelar{
    background-color: #BB1F1F !important;
  }